import React, { FC, useEffect, useState } from 'react'

import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { SparklesIcon } from '@heroicons/react/24/solid'
import {
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  TextField,
  Tooltip,
} from '@mui/material'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import tinycolor from 'tinycolor2'
import { IntegrationType } from 'types/graphql'
import { useDebounceValue } from 'usehooks-ts'

import { toast } from '@redwoodjs/web/toast'

import { IntegrationLogo } from 'src/components/CustomerIntegrations/IntegrationCard'
import { getHexForColor } from 'src/components/HubDash/lib/baserow/baserowColors'
import Button from 'src/components/Library/Button/Button'
import {
  QueuerTaskSwitchboardClient,
  QueuerTaskSwitchboardSwitch,
} from 'src/components/QueuerDashboard/QueuerDashboardSwitchboardCell/QueuerDashboardSwitchboardCell'
import QueuerDashboardSwitchboardSwitchRow from 'src/components/QueuerDashboard/QueuerDashboardSwitchboardSwitchRow'
import { useConfirm } from 'src/lib/hooks/Confirmation'

import useSwitchboardMutations from './useSwitchboardMutations'

interface IntegrationProps {
  queuerTaskSwitchboard: QueuerTaskSwitchboardClient[]
}

const QueuerDashboardSwitchboard: FC<IntegrationProps> = ({
  queuerTaskSwitchboard,
}) => {
  const confirm = useConfirm()
  const mutations = useSwitchboardMutations()
  const [startingJob, setStartingJob] = useState(false)
  const [showOnPar, setShowOnPar] = useState(true)
  const [showInAlert, setShowInAlert] = useState(true)
  const [showRunning, setShowRunning] = useState(true)
  const [showInActive, setShowInActive] = useState(true)
  const [filterClientName, setFilterClientName] = useDebounceValue<string>(
    null,
    500,
  )
  const [filterJobName, setFilterJobName] = useDebounceValue<string>(null, 500)
  const [isSyncing, setIsSyncing] = useState(false)
  const [isClearing, setIsClearing] = useState(false)
  const [changesMade, setChangesMade] = useState(false)
  const [filteredData, setFilteredData] = useState<
    QueuerTaskSwitchboardClient[]
  >([])

  const columnInformation: ColumnsType<QueuerTaskSwitchboardClient> = [
    {
      title: () => (
        <Typography variant="h5">{`Clients (${filteredData.length}) & Switches (${filteredData.reduce(
          (acc, client) => acc + client.switches.length,
          0,
        )})`}</Typography>
      ), // Custom header title
      dataIndex: 'clientName',
      render: (
        clientName: string,
        record: { switches: QueuerTaskSwitchboardSwitch[] },
      ) => {
        return (
          <Typography variant={'h6'}>
            {`${clientName} (${record.switches.length})`}
          </Typography>
        )
      },
    },

    {
      title: () => (
        <Stack direction={'row'} spacing={1} className={'justify-end px-3'}>
          <Typography variant="h5">Status</Typography>
        </Stack>
      ),
      render: (record: {
        isAnyRunning: boolean
        isAnyAlert: boolean
        integrationTypes: IntegrationType[]
      }) => {
        const overallState = record.isAnyAlert
          ? 'BEHIND SCHEDULE'
          : record.isAnyRunning
            ? 'SOME RUNNING'
            : 'ALL ON PAR'
        const overallColor = getHexForColor(
          record.isAnyAlert ? 'red' : record.isAnyRunning ? 'blue' : 'green',
        )
        return (
          <Stack direction={'row'} spacing={1} className={'justify-end'}>
            {record.integrationTypes.map((type, key) => (
              <Tooltip key={key} title={type}>
                <div>
                  <IntegrationLogo integrationType={type} size={'small'} />
                </div>
              </Tooltip>
            ))}
            <Divider orientation="vertical" flexItem />
            <Chip
              size={'small'}
              label={overallState}
              style={{
                backgroundColor: overallColor,
                color: tinycolor(overallColor).darken(40).toString(),
              }}
              data-testid="switchboard-status-chip"
            />
          </Stack>
        )
      },
    },
  ]

  const handleResetSwitch = async (switchId: number) => {
    try {
      await mutations.resetQueuerTaskSwitchboardItem({
        variables: {
          id: switchId,
        },
      })
      toast.success('Switch Reset Successfully')
    } catch (error) {
      toast.error(error?.message || 'An unexpected error occurred.', {
        duration: 2000,
      })
    }
  }

  const handleStartAllJobsInAlert = async () => {
    setStartingJob(true)
    // Get list of all switches in alert and also put the client info in the switch so we can map over later
    const switchesInAlert = queuerTaskSwitchboard
      .map((client) =>
        client.switches.map((switchItem) => ({
          ...switchItem,
          clientName: client.clientName,
          clientId: client.clientId,
        })),
      )
      .flat()
      .filter((switchItem) => switchItem.isAlert && switchItem.active)

    try {
      await mutations.createQueuerJobs({
        variables: {
          input: switchesInAlert.map((switchItem) => ({
            jobType: switchItem.queuerJobType,
            clientsToStart: [
              {
                id: switchItem.clientId,
                name: switchItem.clientName,
                switchboardSwitchId: switchItem.id,
              },
            ],
          })),
        },
      })
    } catch (error) {
      toast.error(error?.message || 'An unexpected error occurred.', {
        duration: 2000,
      })
    }
    setStartingJob(false)
  }

  const handleStartJob = async (switchId: number) => {
    setStartingJob(true)
    const clientItem = queuerTaskSwitchboard.find((client) =>
      client.switches.some((switchItem) => switchItem.id === switchId),
    )
    const switchItem = clientItem.switches.find(
      (switchItem) => switchItem.id === switchId,
    )

    try {
      await mutations.createQueuerJob({
        variables: {
          input: {
            jobType: switchItem.queuerJobType,
            clientsToStart: [
              {
                id: clientItem.clientId,
                name: clientItem.clientName,
                switchboardSwitchId: switchId,
              },
            ],
          },
        },
      })
      toast.success('Job Queued Successfully')
    } catch (error) {
      toast.error(error?.message || 'An unexpected error occurred.', {
        duration: 2000,
      })
    }

    setStartingJob(false)
  }

  const handleTogglePower = async (id: number) => {
    const clientItem = queuerTaskSwitchboard.find((client) =>
      client.switches.some((switchItem) => switchItem.id === id),
    )
    const switchItem = clientItem.switches.find(
      (switchItem) => switchItem.id === id,
    )

    try {
      await mutations.toggleSwitch({
        variables: {
          id: id,
          power: !switchItem.active,
        },
      })
      toast.success('Switch Toggled Successfully')
    } catch (error) {
      toast.error(error?.message || 'An unexpected error occurred.', {
        duration: 2000,
      })
    }
    setChangesMade(true)
  }

  const handleTriggerClearJobs = async () => {
    const isConfirmed = await confirm({
      title: 'Clear ALL jobs?',
      description: 'Are you sure you want to clear all jobs?',
      allowClose: true,
      cancellationText: 'No',
      confirmationText: 'Yes',
    })

    if (!isConfirmed) {
      return
    }
    setIsClearing(true)
    try {
      await mutations.clearBullMQJobs()
      toast.success('BullMQ Jobs Cleared Successfully')
    } catch (error) {
      toast.error(error?.message || 'An unexpected error occurred.', {
        duration: 2000,
      })
    }
    setChangesMade(true)
    setIsClearing(false)
  }

  const handleTriggerSyncJobs = async () => {
    setIsSyncing(true)
    try {
      await mutations.startJobSync()
      toast.success('Job Queued Successfully')
    } catch (error) {
      toast.error(error?.message || 'An unexpected error occurred.', {
        duration: 2000,
      })
    }
    setChangesMade(false)
    setIsSyncing(false)
  }

  useEffect(() => {
    // Function to filter the data based on the provided filters
    const filterData = () => {
      // Convert filter values to lowercase for case-insensitive comparison
      const lowerCaseFilterClientName = filterClientName?.toLowerCase() || ''

      const clientsWithOverallStatuses = queuerTaskSwitchboard.map(
        (client: QueuerTaskSwitchboardClient) => {
          const isAnyRunning = client.switches.some(
            (switchItem) => switchItem.isIncomplete && switchItem.active,
          )
          const isAnyAlert = client.switches.some(
            (switchItem) => switchItem.isAlert && switchItem.active,
          )
          return { ...client, isAnyRunning, isAnyAlert }
        },
      )

      // Apply the filters
      const filteredClients = clientsWithOverallStatuses
        .map((client) => {
          // Filter by client name
          const clientNameMatches = client.clientName
            .toLowerCase()
            .includes(lowerCaseFilterClientName)

          if (!clientNameMatches) {
            // If the client name doesn't match, skip further processing
            return null
          }

          // Filter switches based on job name and alert status
          const filteredSwitches = client.switches.filter((switchItem) => {
            const matchesJobName = filterJobName
              ? filterJobName
                  .toLowerCase()
                  .split(' ') // Split filterJobName into words
                  .every((term) =>
                    switchItem.queuerJobType.toLowerCase().includes(term),
                  )
              : true

            const matchesAlertOnly = showInAlert
              ? switchItem.isAlert && switchItem.active
              : false
            const matchesRunning = showRunning
              ? switchItem.isIncomplete && switchItem.active
              : false
            const matchesOnPar = showOnPar
              ? !switchItem.isIncomplete &&
                !switchItem.isAlert &&
                switchItem.active
              : false
            const matchesActive = showInActive ? !switchItem.active : false

            return (
              matchesJobName &&
              (matchesAlertOnly ||
                matchesRunning ||
                matchesOnPar ||
                matchesActive)
            )
          })

          // Return the client with filtered switches if any switches match
          return filteredSwitches.length > 0
            ? { ...client, switches: filteredSwitches }
            : null
        })
        .filter(Boolean) // Remove null values (clients with no matching switches)

      // Update the filtered data state
      setFilteredData(filteredClients)
    }

    // Call the filter function whenever filter values change
    filterData()
  }, [
    showInAlert,
    showInActive,
    showRunning,
    showOnPar,
    filterClientName,
    filterJobName,
    queuerTaskSwitchboard,
  ]) // Add dependencies
  return (
    <Stack>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        className={'mx-4 min-w-[900px] rounded-md bg-white p-4 shadow'}
      >
        <div className="flex gap-4">
          <TextField
            label="Client Filter"
            size={'small'}
            variant="outlined"
            className="min-w-[150px]"
            onChange={(e) => {
              e.preventDefault()
              setFilterClientName(e.target.value)
            }}
            data-testid="switchboard-client-filter"
          />
          <TextField
            label="Job Filter"
            variant="outlined"
            size={'small'}
            className="min-w-[150px]"
            onChange={(e) => {
              e.preventDefault()
              setFilterJobName(e.target.value)
            }}
          />
          <FormGroup>
            <Stack direction={'row'} spacing={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showOnPar}
                    onChange={() => setShowOnPar(!showOnPar)}
                  />
                }
                label="Show On Par"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showRunning}
                    onChange={() => setShowRunning(!showRunning)}
                  />
                }
                label="Show Running"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showInAlert}
                    onChange={() => setShowInAlert(!showInAlert)}
                  />
                }
                label="Show In Alert"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showInActive}
                    onChange={() => setShowInActive(!showInActive)}
                  />
                }
                label="Show InActive"
              />
            </Stack>
          </FormGroup>
        </div>
        <div className="flex gap-4">
          <Button
            variant="text"
            title="Clear all BullMQ jobs"
            fullWidth={false}
            size={'small'}
            startIcon={<SparklesIcon className="h-5 w-5" />}
            className="w-[110px] min-w-[110px] rounded-lg bg-red-500 text-white hover:bg-red-600"
            loading={isClearing}
            onClick={() => handleTriggerClearJobs()}
            buttonDataTestId="clear-switch-button"
          >
            Clear Jobs
          </Button>
          <Button
            variant="text"
            title="Start All Jobs In Alert"
            fullWidth={false}
            size={'small'}
            startIcon={<SparklesIcon className="h-5 w-5" />}
            className="w-[210px] min-w-[110px] rounded-lg bg-blue-500 text-white hover:bg-blue-600"
            loading={startingJob}
            onClick={handleStartAllJobsInAlert}
            buttonDataTestId="alert-switch-button"
          >
            Start Jobs In Alert
          </Button>

          <Button
            variant="text"
            fullWidth={false}
            size={'small'}
            startIcon={<SparklesIcon className="h-5 w-5" />}
            className="w-[110px] min-w-[110px] rounded-lg bg-green-500 text-white hover:bg-green-600"
            loading={isSyncing}
            onClick={() => handleTriggerSyncJobs()}
            buttonDataTestId="sync-switch-button"
          >
            Sync Jobs
          </Button>
          {changesMade && (
            <Tooltip
              title={
                <Typography variant="caption">
                  Changes made to the switchboard. <br /> Recommend clicking
                  Sync Jobs above to ensure jobs are in line with switchboard.
                </Typography>
              }
            >
              <ExclamationTriangleIcon
                className="h-7 w-7 text-yellow-600"
                aria-hidden="true"
              />
            </Tooltip>
          )}
        </div>
      </Stack>

      <Box className="m-4 min-w-[900px] rounded-md bg-white p-4 shadow">
        <div className={'relative flex w-full flex-row'}>
          <div className="w-full justify-center overflow-y-auto p-3">
            <div className={'rounded-md bg-white'}>
              <Table
                rowClassName={'audit-log-table-row-class'}
                pagination={false}
                dataSource={filteredData}
                columns={columnInformation}
                size={'small'}
                rowKey={(record) => record.clientId}
                expandable={{
                  expandedRowRender: (clientData) => (
                    <QueuerDashboardSwitchboardSwitchRow
                      handleResetSwitch={handleResetSwitch}
                      handleStartJob={handleStartJob}
                      startingJob={startingJob}
                      switches={clientData.switches}
                      handleTogglePower={handleTogglePower}
                    />
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </Box>
    </Stack>
  )
}

export default QueuerDashboardSwitchboard
